<template>
  <div>
    <div class="pc head-container">
      <div class="flex-align" style="gap:20px">
        <img class="account-img" src="/static/icon/avata_default.png" alt="" />
        <div>
          <div class="subtitle3 main">{{ user.name }}</div>
          <button class="button is-gray margin-top-8" style="width:105px;height:32px" @click="routerPush('profile')">
            회원정보 수정
          </button>
        </div>
      </div>
    </div>
    <div class="pc container">
      <div class="h5 main" style="padding-top:40px;padding-bottom:24px">내 서비스</div>
      <button
        v-if="!has_order"
        class="button is-dark size-18"
        style="width:100%;height:58px;cursor:unset;margin-bottom:16px"
      >
        👏 첫 구매 최대 44% 추가 할인
      </button>
      <template v-if="list">
        <card-my-service-pc
          v-for="(card, idx) in list"
          :key="'card-my-service-' + idx"
          :card="card"
          @getData="getData"
        ></card-my-service-pc>
      </template>

      <template v-if="list && list.length === 0">
        <div class="body2 sub3 text-center margin-top-40">
          <div>내 서비스가 아직없습니다.<br />내 서비스를 개설하고 첫 구매 혜택도 받아보세요.</div>
          <div
            style="margin-top:10px;text-decoration: underline"
            class="primary unselect"
            @click="routerPush('/theme_market')"
          >
            서비스 개설하기
          </div>
        </div>
      </template>
    </div>
    <div class="mobile container">
      <div class="h7 main" style="padding:24px 0 16px 0">내 서비스</div>
      <button
        v-if="!has_order"
        class="button is-dark size-15"
        style="width:100%;height:58px;cursor:unset;margin-bottom:16px"
      >
        👏 첫 구매 최대 44% 추가 할인
      </button>
      <template v-if="list">
        <card-my-service v-for="(card, idx) in list" :key="'card-my-service-' + idx" :card="card"></card-my-service>
      </template>
      <template v-if="list && list.length === 0">
        <div class="body4 sub3 text-center margin-top-40">
          <div>내 서비스가 아직없습니다.<br />내 서비스를 개설하고 첫 구매 혜택도 받아보세요.</div>
          <div
            style="margin-top:10px;text-decoration: underline"
            class="primary unselect"
            @click="routerPush('/theme_market')"
          >
            서비스 개설하기
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import UserAPIMixin from '../../mixins/UserAPIMixin';
import CardMyService from '../component/CardMyService';
import CardMyServicePc from '../component/CardMyServicePc';
const io = require('socket.io-client');

export default {
  name: 'MyService',
  components: { CardMyServicePc, CardMyService },
  mixins: [UserAPIMixin],
  created() {
    this.getHasOrder();
    this.getData();
    this.setSocketIO();
  },
  data() {
    return {
      list: undefined,
      socket: undefined,
      has_order: true,
    };
  },
  beforeDestroy() {
    this.socket.disconnect();
  },
  methods: {
    getHasOrder() {
      this.request.user.get('launchpack/has_order').then((res) => {
        this.has_order = res.data.result;
      });
    },
    setSocketIO() {
      this.socket = io.connect('https://master-dev.launchpack.co.kr:3000');
      setTimeout(() => {
        this.socket.on('message', (msg) => {
          if (msg.create_service) {
            this.getData();
          }
        });
      }, 500);
    },
    getData() {
      this.$store.commit('setLoading', true);
      this.request.user
        .get('/launchpack/v1/theme_skin/order?service_type=4&page_length=100')
        .then((res) => {
          /*res.data.data.forEach(menu=>{
              menu.homepage = 'https://' + menu.domain.replace('https://','');
            });*/
          this.list = res.data.data;
          this.$store.commit('setLoading', false);
        })
        .catch(() => {
          this.$store.commit('setLoading', false);
        });
    },
  },
};
</script>

<style lang="stylus" scoped>
.head-container
  width 100%
  height 144px
  background-color white
  display flex
  align-items center
  justify-content center

.account-img
  width 80px
  height 80px
</style>
